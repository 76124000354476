import type { LoggedInUser } from 'common/user-context'
import * as roles from 'common/utils/roles'

export const LEGAL_HERO_FOL = 'Zeugnisprüfung Legal Hero'

export const FIELDS_OF_LAW: { [key: string]: [] } = {
  Arbeitsrecht: [],
  'Arbeitsrecht: Abmahnung': [],
  'Arbeitsrecht: Arbeitsvertrag prüfen': [],
  'Arbeitsrecht: Aufhebungsvertrag': [],
  'Arbeitsrecht: Kündigung': [],
  'Arbeitsrecht: Sonstiges': [],
  'Arbeitsrecht: Zeugnisprüfung': [],
  'Arbeitsrecht: International': [],
  Arzthaftung: [],
  Asylrecht: [],
  Aufhebungsvertrag: [],
  Bankrecht: [],
  Baurecht: [],
  Beamtenrecht: [],
  'DSGVO / Datenschutz': [],
  Dieselskandal: [],
  Erbrecht: [],
  'Erbrecht: International': [],
  Fahrradrecht: [],
  Familienrecht: [],
  'Familienrecht: International': [],
  Gesellschaftsrecht: [],
  Gründung: [],
  Immobilienrecht: [],
  Insolvenzrecht: [],
  'Kündigung Arbeitsverhältnis': [],
  Markenrecht: [],
  Medizinrecht: [],
  Mietrecht: [],
  'Mineko - Mietnebenkostencheck': [],
  Migrationsrecht: [],
  Ordnungswidrigkeiten: [],
  'Öffentliches Baurecht': [],
  'PKV-Beitragsrückerstattung': [],
  'Prüfung Kündigung': [],
  Reiserecht: [],
  'Reiserecht: International': [],
  Schadensersatz: [],
  'SB-Verzicht Kulanz': [],
  'Schadensersatz Verkehrsrecht': [],
  'Sonstige Rechtsfragen': [],
  Sozialrecht: [],
  'Sozialrecht: International': [],
  Steuerrecht: [],
  Strafrecht: [],
  Urheberrecht: [],
  Vereinsrecht: [],
  Verkehrsrecht: [],
  'Verkehrsrecht: International': [],
  Verkehrsunfall: [],
  Versicherungsrecht: [],
  Vertragsrecht: [],
  'Vertragsrecht: International': [],
  Verwaltungsrecht: [],
  Wettbewerbsrecht: [],
  Waffenrecht: [],
  Wohnungseigentumsrecht: [],
  [LEGAL_HERO_FOL]: [],
}

type FOLEntries = Array<{ id: string; name: string }>

export const FIELDS_OF_LAW_ENTRIES: FOLEntries = Object.keys(FIELDS_OF_LAW).map(key => ({ id: key, name: key }))

/**
 * Make sure these rules in alignment with
 * https://klugo-gmbh.atlassian.net/wiki/spaces/KLUG/pages/2728919041/Rechtsgebiete
 *
 * Most field of laws are assignable to all roles. But for some roles, we have lists
 * of things that they are not allowed to assign. Unfortunaly these lists don't follow
 * logical/strict ruls, but are often only due to specific contract-designs.
 */
const FILTERS_PER_ROLE = {
  // Callcenter (i.e vocateur)
  CALLCENTER: FIELDS_OF_LAW_ENTRIES.filter(
    (k: { id: string }): boolean =>
      ![
        'Arzthaftung',
        'Gründung',
        'Dieselskandal',
        'Insolvenzrecht',
        'Mineko - Mietnebenkostencheck',
        'Prüfung Kündigung',
        LEGAL_HERO_FOL,
      ].includes(k.id)
  ),
  // Inbound-Lawyers
  TRB: FIELDS_OF_LAW_ENTRIES.filter(
    (k: { id: string }): boolean =>
      ![
        'Arzthaftung',
        'Beamtenrecht',
        'Dieselcheck',
        'Gründung',
        'Insolvenzrecht',
        'Prüfung Kündigung',
        LEGAL_HERO_FOL,
        'Arbeitsrecht: International',
        'Erbrecht: International',
        'Familienrecht: International',
        'Öffentliches Baurecht',
        'Reiserecht: International',
        'Sozialrecht: International',
        'Verkehrsrecht: International',
        'Vertragsrecht: International',
        'Vereinsrecht',
        'Waffenrecht',
      ].includes(k.id)
  ),
  // Insurance colleagues
  DEVK: FIELDS_OF_LAW_ENTRIES.filter(
    (k: { id: string }): boolean =>
      ![
        'Beamtenrecht',
        'Dieselcheck',
        'Gründung',
        'Insolvenzrecht',
        'Prüfung Kündigung',
        'Arbeitsrecht: International',
        'Erbrecht: International',
        'Familienrecht: International',
        'Öffentliches Baurecht',
        'Reiserecht: International',
        'Sozialrecht: International',
        'Vereinsrecht',
        'Verkehrsrecht: International',
        'Vertragsrecht: International',
        'Vereinsrecht',
        'Beamtenrecht',
        'Öffentliches Baurecht',
        'Waffenrecht',
      ].includes(k.id)
  ),
  // Us
  ADMIN: FIELDS_OF_LAW_ENTRIES,
  // Us
  KLUGO: FIELDS_OF_LAW_ENTRIES.filter(
    (k: { id: string }): boolean =>
      !['Arzthaftung', 'Mineko - Mietnebenkostencheck', 'Öffentliches Baurecht', 'Prüfung Kündigung', LEGAL_HERO_FOL].includes(
        k.id
      )
  ),
  // The undefined rests receives nothing.
  // Better safe then sorry.
  FALLBACK: [],
}

export const FIELDS_OF_LAW_ENTRIES_FILTERED = (user: LoggedInUser | undefined): FOLEntries =>
  roles.isAdmin(user)
    ? FILTERS_PER_ROLE.ADMIN
    : roles.isEmployee(user)
    ? FILTERS_PER_ROLE.KLUGO
    : roles.isCallcenter(user)
    ? FILTERS_PER_ROLE.CALLCENTER
    : roles.isTRB(user) || roles.isTRBManager(user)
    ? FILTERS_PER_ROLE.TRB
    : roles.isChannel(user)
    ? FILTERS_PER_ROLE.DEVK
    : // We (i.e Klugo) isn't quite sure about
    // how to handle lawyers yet. Until we do,
    // we assume they behave like DEVK (see !1857)
    roles.isLawyer(user)
    ? FILTERS_PER_ROLE.DEVK
    : FILTERS_PER_ROLE.FALLBACK

/**
 * Run the commented out Code at the bottom of the module
 * to get the updated version of the following output.
 *
 * Used to display which user role can assign which FOL
 * on the FOL List page
 */

export const FIELDS_OF_LAW_ROLE_PERMISSIONS_STRINGIFIED: Record<string, string> = {
  Arbeitsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Arbeitsrecht: Abmahnung': 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Arbeitsrecht: Arbeitsvertrag prüfen': 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Arbeitsrecht: Aufhebungsvertrag': 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Arbeitsrecht: Kündigung': 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Arbeitsrecht: Sonstiges': 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Arbeitsrecht: Zeugnisprüfung': 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Arbeitsrecht: International': 'Admin, Klugo, Callcenter',
  Arzthaftung: 'Admin, DEVK',
  Asylrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Aufhebungsvertrag: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Bankrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Baurecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Beamtenrecht: 'Admin, Klugo, Callcenter',
  'DSGVO / Datenschutz': 'Admin, Klugo, DEVK, Callcenter, TRB',
  Dieselcheck: 'Admin, Klugo, Callcenter',
  Dieselskandal: 'Admin, Klugo, DEVK, TRB',
  Erbrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Erbrecht: International': 'Admin, Klugo, Callcenter',
  Fahrradrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Familienrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Familienrecht: International': 'Admin, Klugo, Callcenter',
  Gesellschaftsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Gründung: 'Admin, Klugo',
  Immobilienrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Insolvenzrecht: 'Admin, Klugo',
  'Kündigung Arbeitsverhältnis': 'Admin, Klugo, DEVK, Callcenter, TRB',
  Markenrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Medizinrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Mietrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Mineko - Mietnebenkostencheck': 'Admin, DEVK, TRB',
  Migrationsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Ordnungswidrigkeiten: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Öffentliches Baurecht': 'Admin, Klugo, Callcenter, TRB',
  'PKV-Beitragsrückerstattung': 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Prüfung Kündigung': 'Admin',
  Reiserecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Reiserecht: International': 'Admin, Klugo, Callcenter',
  'SB-Verzicht Kulanz': 'Admin, Klugo, DEVK, Callcenter, TRB',
  Schadensersatz: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Schadensersatz Verkehrsrecht': 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Sonstige Rechtsfragen': 'Admin, Klugo, DEVK, Callcenter, TRB',
  Sozialrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Sozialrecht: International': 'Admin, Klugo, Callcenter',
  Steuerrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Strafrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Urheberrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Vereinsrecht: 'Admin, Klugo, Callcenter',
  Verkehrsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Verkehrsrecht: International': 'Admin, Klugo, Callcenter',
  Verkehrsunfall: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Versicherungsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Vertragsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Vertragsrecht: International': 'Admin, Klugo, Callcenter',
  Verwaltungsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Wettbewerbsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  Waffenrecht: 'Admin, Klugo, Callcenter',
  Wohnungseigentumsrecht: 'Admin, Klugo, DEVK, Callcenter, TRB',
  'Zeugnisprüfung Legal Hero': 'Admin, DEVK',
}

/**
 * @todo implement a prettier solution
 */

// type CanSee = Record<keyof typeof FILTERS_PER_ROLE, boolean>
// const roleNames = ['Admin', 'Klugo', 'DEVK', 'Callcenter', 'TRB']

// const canSeeFol = (fol: { id: string }): CanSee => {
//   const result: Partial<CanSee> = {}

//   for (const role of roleNames) {
//     result[role] = !!FILTERS_PER_ROLE[role.toUpperCase()].find(({ id }) => fol.id === id)
//   }

//   return result as CanSee
// }

// const canSeeToString = (canSee: CanSee): string =>
//   Object.entries(canSee)
//     .map(([key, value]) => (value ? key : ''))
//     .filter(Boolean)
//     .join(', ')

// const canSees = Object.fromEntries(FIELDS_OF_LAW_ENTRIES.map(fol => [fol.id, canSeeToString(canSeeFol(fol))]))

// console.log(JSON.stringify(canSees, null, 2))
