import React, { Fragment, FunctionComponent } from 'react'
import { Col } from 'reactstrap'

import { DateStaticField, StaticField } from 'common/components'
import { StaticRow } from 'common/components/StaticRow'
import { ROLES, ROLE_IDS } from 'common/constants'
import { formatDateTime } from 'common/utils'
import { AdviceListStatusLabel } from 'packages-mui/advice/components/AdviceListRoleStatusLabel'
import { Status } from 'packages-mui/advice/interfaces'
import { computeChannelStatus } from 'packages-mui/advice/utils/computeChannelStatus'

import { CONTACT_TYPE_LABELS } from '../../constants'
import { AdviceRequest } from '../../interfaces'

interface StatusRowProps {
  adviceRequest: AdviceRequest
  isTRB?: boolean
  isAdmin?: boolean
  isChannel?: boolean
}

export function getPartnerLabel<T extends { partner?: { name?: string; id?: string } | null; fromTrb?: string | null }>(
  adviceRequest: T,
  isAllowed: boolean
): string {
  return isAllowed &&
    (adviceRequest.partner?.id === 'devk-trb' || adviceRequest.partner?.id === 'roland-trb') &&
    adviceRequest.fromTrb
    ? `${adviceRequest.partner?.name} (${adviceRequest.fromTrb})`
    : adviceRequest.partner?.name || '-'
}

export const StatusRow: FunctionComponent<StatusRowProps> = ({ adviceRequest, isTRB, isAdmin, isChannel }: StatusRowProps) => {
  const role = isTRB
    ? ROLES[ROLE_IDS.TRB]
    : isAdmin
    ? ROLES[ROLE_IDS.Administrator]
    : isChannel
    ? ROLES[ROLE_IDS.Channel]
    : ROLES[ROLE_IDS.Callcenter]

  const status = isChannel
    ? computeChannelStatus({
        status: adviceRequest.status,
        revenue: adviceRequest.revenue,
      })
    : adviceRequest.status

  /* display chancellery name for trb requests */
  const partnerLabel = getPartnerLabel(adviceRequest, !!(isAdmin || isChannel || role === ROLES[ROLE_IDS.Callcenter]))

  const isCallback = adviceRequest.status === Status.AWAITING_CALLBACK
  // Show KLUGO as callbacking unit when chanc is not handling it
  const callbackBy =
    adviceRequest?.chancellery && !adviceRequest?.chancellery?.callbacksDisabled ? adviceRequest?.chancellery?.name : 'KLUGO'
  // Only show the callbacktime when its handled by KLUGO
  const callbackAt =
    !adviceRequest?.chancellery?.callbacks && adviceRequest.preferredCallbackTime
      ? `am ${formatDateTime(new Date(adviceRequest.preferredCallbackTime))}`
      : ''
  return (
    <Fragment>
      <StaticRow>
        <Col md={3}>
          <StaticField label="Beratungs-ID" value={adviceRequest.adviceId || '-'} />
        </Col>
        <Col md={3}>
          <StaticField label="Status" value={<AdviceListStatusLabel status={status} role={role} />} />
        </Col>
        <Col md={3}>
          <StaticField
            label="Kontaktart"
            value={adviceRequest.contactType ? CONTACT_TYPE_LABELS[adviceRequest.contactType] : ''}
          />
        </Col>
        {!!adviceRequest.createdAt && (
          <Col md={3}>
            <DateStaticField label="Erstellt" value={adviceRequest.createdAt} />
          </Col>
        )}
      </StaticRow>
      <StaticRow>
        <Col md={3}>
          <StaticField label="Rechtsgebiet" value={adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.name : '-'} />
        </Col>
        <Col md={3}>
          <StaticField label="Sprache" value={adviceRequest.language ? adviceRequest.language.language : 'Deutsch'} />
        </Col>
        {adviceRequest.partner && (
          <Col md={3}>
            <StaticField label="Partner" value={partnerLabel} />
          </Col>
        )}
        {isChannel && isCallback && (
          <Col md={3}>
            <StaticField label="Rückruf ausstehend" value={`via ${callbackBy} ${callbackAt}`} />
          </Col>
        )}
      </StaticRow>
    </Fragment>
  )
}
